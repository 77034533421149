<template>
  <div ref="container">
    <iframe ref="iframe"  :src="url" width="100%" frameborder="0"></iframe>   
  
  </div>
 
</template>

<script> 
export default {
   data() {
       return {
           url: 'http://mhp-company.mymhp.cn:9999'  // 你想要嵌入的网页地址
       };
   },
   mounted() {
       this.updateIframeHeight();
       window.addEventListener('resize', this.updateIframeHeight);
   },
   beforeDestroy() {
       window.removeEventListener('resize', this.updateIframeHeight);
   },
   methods: {
       updateIframeHeight() {
           
           let iframe = this.$refs.iframe;
           
           // 获取浏览器视口高度
           let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
           viewportHeight = viewportHeight-130
           // 设置iframe的高度为获取到的视口高度，同时保证最小高度为200px
           iframe.style.height = `${Math.max(viewportHeight, 600)}px`;
       }
   }
};
</script>